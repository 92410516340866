import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { NewsroomService, LocalizationService, CommonService, LoadingService, FormService } from "../../../services";
import { News, NewsConfig, Newsroom, Form } from "../../../models";
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {

  currentLang;
  newsId: string;
  newsDetails: News;
  httpRequesSended: boolean = false;
  newsroomConfig: NewsConfig;
  researched_path: number = 0;
  newsroomList: Newsroom;
  prevPath: number = 0;
  prevTitle: string;
  nextPath: number = 0;
  nextTitle: string;
  prevDate: string;
  nextDate: string;
  contentType: [string] = ['all'];
  tag: [string] = ['all'];
  date: string = 'all';
  hotNews: News;
  formId: string;
  form: Form;
  sended: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formService: FormService,
    private commonService: CommonService,
    private loadingService: LoadingService,
    private newsroomService: NewsroomService,
    private localization: LocalizationService,
    private meta: Meta,
    private title: Title
  ) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.newsId = params['news_id'];
      this.localization.lang.subscribe(lang => {
        this.currentLang = lang;
        this.route.queryParams.subscribe(params => {
          this.contentType = params.contentType;
          this.tag = params.tag;
          this.date = params.date;
        });
        this.getNewsroomConfig();
      });
    });
  }

  /**
   * Get Newsroom config, getHotNews and finally,
   * getNewsDetails, but sequentially.
   */
  getNewsroomConfig(): void {
    this.newsroomService.getNewsroomConfig()
      .subscribe((newsConfig: NewsConfig) => {
        this.newsroomConfig = newsConfig;
        this.getHotNews();
      });
  }

  /**
   * Get hot news
   */
  getHotNews(): void {
    this.newsroomService.getNewsroomList('0', '1', this.contentType, this.tag, this.date, 'true')
      .subscribe((newsroom: Newsroom) => {
        if (newsroom?.news) {
          this.getNewsroomList('10', newsroom.news[0])
        } else {
          this.getNewsroomList('12', null)
        }
      });
  }

  /**
   * Get Newsroom list
   */
  getNewsroomList(lenght: string, hotNews: News | null): void {
    this.newsroomService.getNewsroomList('0', '100', this.contentType, this.tag, this.date)
      .subscribe((newsroom: Newsroom) => {
        this.loadingService.stopLoading();
        this.newsroomList = newsroom;
        if (hotNews != null) {
          this.newsroomList.news.splice(6, 0, hotNews)
        }
        /**
         * Time to getNewsDetails.
         */
        this.getNewsDetails(this.newsId)
      });
  }

  /**
   * Get news details
   */
  getNewsDetails(newsId): void {
    if (!this.httpRequesSended) {
      this.newsroomService.getNewsroomDetails(newsId)
        .subscribe((news: News) => {
          this.newsDetails = news;
          this.newsroomService.appendMatomo(this.newsDetails.script_matomo);
          this.loadingService.stopLoading();
          setTimeout(() => this.localization.insertCaptions(), 1000);
          this.httpRequesSended = true;
          this.title.setTitle(this.newsDetails.title);
          this.meta.removeTag("property='og:image'");
          this.meta.removeTag("property='og:image'");
          this.meta.removeTag("property='og:image:type'");
          this.meta.removeTag("property='og:image:width'");
          this.meta.removeTag("property='og:image:height'");
          this.meta.removeTag("property='og:image:secure_url'");
          this.meta.removeTag("property='og:image:alt'");
          this.meta.addTag({ property: 'og:title', content: this.newsDetails.title });
          this.meta.addTag({ name: 'twitter:title', content: this.newsDetails.title });
          this.meta.addTag({ property: 'og:type', content: "website" });
          this.meta.addTag({ property: 'og:image', content: this.newsDetails.image.url });
          this.meta.addTag({ property: 'og:url', content: this.getPageUrl() });
          this.loadNewsPath();
        });
    }
  }

  /**
   * Get news path
   */
  loadNewsPath() {
    var news_path = this.newsDetails.path.split('/')[2];
    this.researched_path = this.newsroomList.paths.indexOf(news_path);
    this.prevPath = this.researched_path - 1;
    this.prevTitle = this.newsroomList.titles[this.prevPath];
    this.prevDate = this.newsroomList.dates[this.prevPath];
    this.nextPath = this.researched_path + 1;
    this.nextTitle = this.newsroomList.titles[this.nextPath];
    this.nextDate = this.newsroomList.dates[this.nextPath];
  }

  /**
   * Go to previous or next reference
   * reference: Reference
   */
  goToNews(path): void {
    var news_path = this.newsroomList.paths[path];
    this.loadingService.startLoading();
    this.router.navigate([this.currentLang + '/newsroom/', news_path], { queryParams: { contentType: this.contentType, tag: this.tag, date: this.date } }).then(() => {
      let el = document.getElementById('top-hide-header');
      el?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    });
  }

  /**
   * Go to news details
   * newsPath : string
   */
  goToDetails(newsPath: string): void {
    this.router.navigate([this.currentLang + '/newsroom/', newsPath]).then(() => {
      let el = document.getElementById('top-hide-header');
      el?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    });
  }

  /**
   * Go to CTA
   * newsId:number
   */
  goTo(route: any) {
    return this.commonService.goToUrl(route);
  }

  /**
   * format date
   *
   * @param date
   * @param format
   */
  formatDate(date: string, format: string = null) {
    let d = date.split(' ');
    switch (format) {
      case 'day': return d[0];
      case 'month': return d[1];
      case 'year': return d[2];
      default: return date;
    }
  }

  /**
   * get page url
   */
  getPageUrl(): string {
    return window.location.href;
  }
}
