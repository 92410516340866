import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LocalizationService } from './localization.service';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { LoadingService } from "./loading.service";


@Injectable({
  providedIn: 'root'
})
export class FormService extends RestService {

  constructor(private loadingService: LoadingService, http: HttpClient, localization: LocalizationService) {
    super(http, localization);
  }

  /**
   * get node by id
   * @param form_id
   */
  getForm(form_id: string): Observable<any> {
    return this.http.get<any>(FormService.apiHost + 'form/' + form_id).pipe(
      map((res) => {
      //  this.localization.setAliases(res.aliases);
        return res.form
      }),
      catchError((err) => {
        this.loadingService.stopLoading()
        return FormService.handleError(err);
      })
    );
  }

  /**
   * get newsletter udpate form
   * @param form_id
   */
  getUpdateForm(form_id: string, contact_id): Observable<any> {
    return this.http.get<any>(FormService.apiHost + 'form/' + form_id + '/' + contact_id).pipe(
      map((res) => {
        this.localization.setAliases(res.aliases);
        return res.form;
      }),
      catchError((err) => {
        this.loadingService.stopLoading()
        return FormService.handleError(err);
      })
    );
  }

  getUpdateFormData(form_id: string, contact_id: string, data: {}): Observable<any> {
    return this.http.post<any>(FormService.apiHost + 'form/' + form_id + '/' + contact_id, data).pipe(
      map((res) => { return res.result }),
      catchError((err) => {
        this.loadingService.stopLoading()
        return FormService.handleError(err);
      })
    );
  }

  /**
   * get node by id
   * @param form_id
   * @param data
   */
  sendMail(form_id: string, data: {}): Observable<any> {
    const body = new HttpParams();
    Object.keys(data).forEach(key => {
      let value = data[key];
      body.set(key, value);
    });

    return this.http.post<any>(FormService.apiHost + 'form/' + form_id, data).pipe(
      map((res) => { return res.result }),
      catchError((err) => {
        this.loadingService.stopLoading()
        return FormService.handleError(err);
      })
    );
  }

  /**
   * get form data by id
   * @param form_id
   * @param data
   */
  getFormData(form_id: string, data: {}): Observable<any> {
    return this.http.post<any>(FormService.apiHost + 'form/' + form_id, data).pipe(
      map((res) => { return res.result }),
      catchError((err) => {
        this.loadingService.stopLoading()
        return FormService.handleError(err);
      })
    );
  }
}


