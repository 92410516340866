<section class="generic-header" [style.background-image]="'url(' + form?.config?.image + ')'">
  <div class="text-container">
    <h1 class="title"> {{ form?.config?.title }} </h1>
    <div class="content">
      {{ form?.config?.subtitle }}
    </div>
  </div>
</section>

<section class="generic-breadcrumb" id="generic-section">
  <div class="row">
    <div class="col-sm-12">
      <a href="/"><img src="../assets/img/icon/home.png" alt="home"/></a> |
      <span> {{ form?.config?.title }} </span>
    </div>
  </div>
</section>

<section class="generic-content pb-5" *ngIf="form && !sended">
  <div class="row">
    <div class="col-md-12 col-lg-8 col-sm-12 content" [innerHTML]="form?.config?.content | safeHtml">
    </div>

    <div class="col-lg-4 col-md-12 col-sm-12 pl-md-5 Form_92">
      <h3 class="pt-3 text-center text-muted"> {{ form?.config?.form_title }} </h3>
      <form class="form-contact" name="contactForm" #contactForm="ngForm" (ngSubmit)="submit(contactForm)">
        <div *ngFor="let field of form?.fields" [ngSwitch]="field.type" class="col-sm-12">
          <div class="form-group" *ngSwitchCase="'textfield'">
            <input type="text" class="form-control" [name]="field.id" [id]="field.id" [required]="field.required"
                   [placeholder]="field.required ? field.title + '*' : field.title" ngModel/>
          </div>

          <div class="form-group" *ngSwitchCase="'email'">
            <input type="text" class="form-control" [name]="field.id" [id]="field.id" [required]="field.required"
              [placeholder]="field.required ? field.title + '*' : field.title" ngModel />
          </div>

          <div class="form-group" *ngSwitchCase="'textarea'">
            <textarea class="form-control" [name]="field.id" [id]="field.id" [required]="field.required"
                      [placeholder]="field.title" style="height: 310px" ngModel></textarea>
          </div>

          <div class="form-group" *ngSwitchCase="'select'">
            <select class="form-control amc-select" [name]="field.id" [id]="field.id" [required]="field.required" [ngModel]="field.options[0].id">
              <option [value]="" [selected]="true">
                {{ field.title }}
              </option>
              <option *ngFor="let option of field.options" [value]="option.id">
                {{ option.value }}
              </option>
            </select>
          </div>

          <div class="form-group" *ngSwitchCase="'checkbox'">
            <label *ngIf="field.id != 'field_select_all_tags'" class="checkbox-container">
              {{ field.title }}
              <input type="checkbox" [name]="field.id" [id]="field.id" [required]="field.required" ngModel>
              <span class="checkmark"></span>
            </label>

            <label *ngIf="field.id == 'field_select_all_tags'" class="checkbox-container">
              {{ field.title }}
              <input type="checkbox" [name]="field.id" [id]="field.id" [required]="field.required" ngModel (change)="selectAllCheckboxes($event)">
              <span class="checkmark"></span>
            </label>
          </div>

          <div class="form-group" *ngSwitchCase="'checkboxes'">
              <label class="checkbox-container" *ngFor="let option of field.options" [value]="option.id">
                {{ option.value }}
                <input type="checkbox" [name]="option.id" [id]="option.id" ngModel (change)="unselectCheckbox($event)">
                <span class="checkmark"></span>
              </label>
          </div>
        </div>

        <div class="col-sm-12 mb-3" *ngIf="cookieAccepted && recapcha">
          <re-captcha class="captcha_class" (resolved)="resolved($event)" siteKey="{{ recapchaSiteKey }}"></re-captcha>
        </div>

        <div class="contact_btn col-sm-12 mb-3" *ngIf="!cookieAccepted">
          <div class="text-white" [innerHTML]="cookieConfig?.cookie_form_text?.value"></div>
          <button type="button" class="form_btn amc-btn-light" (click)="accept()">
            {{ cookieConfig?.cookie_accept_text }} Cookies
          </button>
        </div>

        <div class="contact_btn col-sm-12">
          <button type="submit" class="amc-btn-light-contact d-flex">
            {{ form?.config?.submit_text }}
            <img src="/assets/img/icon/arrow_cta.png" class="white" alt="Arrow CTA">
          </button>
        </div>
      </form>
    </div>
  </div>
</section>

<section class="generic-content" *ngIf="sended">
  <div class="row">
    <div class="col-sm-12 content" [innerHTML]="form?.config?.confirmation_message">
    </div>
  </div>
</section>
